<template id="home">
  <b-container fluid class="p-2">
    <b-card title="Home">
      <b-card-body>
        <b-img
          thumbnail
          :src="user.avatar_url"
          :alt="user.nomeCompleto"
          width="150"
          class="mb-3"
        />
        <p>
          Hello <span class="font-weight-bold">{{ user.nomeCompleto }}</span
          >,
        </p>
        <p>
          You are: <span style="color: #28a745">{{ gruppi }}</span>
        </p>
        <p>
          Last login:
          <span class="font-weight-bold">{{
            (last_login ? last_login.created_at : "") | formatDateHour
          }}</span>
        </p>
        <div v-if="user.policy_required" class="mt-5">
          <p>
            <strong>Esonero di responsabilità per la perdita di dati</strong>
          </p>

          <p>
            Accedendo e utilizzando questo software, l'utente accetta i seguenti
            termini e condizioni. Si prega di leggere attentamente prima di
            procedere.
          </p>

          <p>
            <strong>Accettazione dei rischi:</strong> L'utente riconosce e
            accetta che l'utilizzo di questo software comporta rischi di perdita
            di dati. Tali rischi possono derivare da malfunzionamenti tecnici,
            errori umani, attacchi informatici o altre cause impreviste.
            L'utente comprende che la sicurezza dei dati non può essere
            garantita al cento per cento e che non ci sono garanzie esplicite o
            implicite riguardo all'incolumità dei dati.
          </p>

          <p>
            <strong>Esonero di responsabilità:</strong> In nessuna circostanza
            l'autore o il fornitore di questo software saranno ritenuti
            responsabili per la perdita di dati, danni diretti o indiretti,
            danni consequenziali, perdite finanziarie o qualsiasi altra perdita
            o danno derivante dall'uso o dall'incapacità di utilizzare questo
            software, anche se l'autore o il fornitore sono stati avvisati della
            possibilità di tali danni.
          </p>

          <p>
            <strong>Limitazione di responsabilità:</strong> Nel caso in cui la
            legge applicabile non consenta l'esclusione o la limitazione di
            responsabilità per danni diretti o indiretti, la responsabilità
            totale dell'autore o del fornitore di questo software sarà limitata
            al massimo estentore consentito dalla legge.
          </p>

          <p>
            <strong>Liberatoria:</strong>L'utente rilascia l'autore e il
            fornitore di questo software da qualsiasi responsabilità, pretesa,
            azione legale o richiesta di risarcimento danni derivante dall'uso
            di questo software.
          </p>

          <p>
            <strong>Tenuta indenne:</strong> L'utente accetta di tenere indenne
            l'autore e il fornitore di questo software da qualsiasi perdita,
            danni, costi o spese, inclusi gli onorari legali, derivanti da
            qualsiasi violazione da parte dell'utente dei presenti termini e
            condizioni.
          </p>

          <p><strong>Cessione dei diritti sull'utilizzo dei dati</strong></p>

          <p>
            In aggiunta ai termini e alle condizioni sopra riportati, l'utente
            riconosce e accetta che qualsiasi dato inserito o fornito attraverso
            l'utilizzo di questo software diventa di proprietà del proprietario
            del software. L'utente cede irrevocabilmente e trasferisce al
            proprietario del software tutti i diritti, i titoli e gli interessi
            su tali dati, inclusi, ma non limitati a, i diritti di riproduzione,
            distribuzione, elaborazione e qualsiasi altro diritto di utilizzo, a
            fini commerciali o non commerciali, senza necessità di ulteriori
            autorizzazioni o consensi.
          </p>

          <p>
            L'utente rinuncia espressamente a qualsiasi pretesa, diritto o
            interesse su tali dati e accetta di non avanzare alcuna richiesta di
            risarcimento o di qualsiasi altra forma di compensazione nei
            confronti del proprietario del software per l'utilizzo dei dati
            ceduti.
          </p>

          <p>
            L'utente comprende e accetta che il proprietario del software ha il
            diritto di utilizzare i dati ceduti in conformità con le leggi
            applicabili sulla privacy e sulla protezione dei dati, e che tali
            dati potrebbero essere utilizzati per migliorare il funzionamento
            del software, per scopi statistici, per la personalizzazione
            dell'esperienza dell'utente, o per altri scopi legittimi.
          </p>

          <p style="font-weight: bold; color: #28a745">
            <b-form-checkbox
              v-model="policy_accepted"
              name="policy"
              :value="1"
              :unchecked-value="0"
              >Accettando i presenti termini e condizioni, l'utente dichiara di
              comprendere e accettare pienamente le disposizioni di entrambe le
              sezioni. L'utilizzo di questo software implica la consapevolezza e
              l'accettazione dei rischi connessi alla perdita di dati e alla
              cessione dei diritti sull'utilizzo dei dati.</b-form-checkbox
            >
          </p>

          <p><em>Data dell'ultimo aggiornamento: 10 maggio 2024</em></p>

          <br />

          <p><strong>Disclaimer for Data Loss</strong></p>

          <p>
            By accessing and using this software, the user agrees to the
            following terms and conditions. Please read carefully before
            proceeding.
          </p>

          <p>
            <strong>Risk Acceptance:</strong> The user acknowledges and accepts
            that using this software entails risks of data loss. Such risks may
            result from technical malfunctions, human errors, cyberattacks, or
            other unforeseen causes. The user understands that data security
            cannot be guaranteed one hundred percent, and there are no explicit
            or implicit guarantees regarding data integrity.
          </p>

          <p>
            <strong>Liability Disclaimer:</strong> Under no circumstances shall
            the author or provider of this software be held responsible for data
            loss, direct or indirect damages, consequential damages, financial
            losses, or any other loss or damage arising from the use or
            inability to use this software, even if the author or provider has
            been notified of the possibility of such damages.
          </p>

          <p>
            <strong>Limitation of Liability:</strong> If applicable law does not
            allow the exclusion or limitation of liability for direct or
            indirect damages, the total liability of the author or provider of
            this software shall be limited to the maximum extent permitted by
            law.
          </p>

          <p>
            <strong>Release:</strong> The user releases the author and provider
            of this software from any liability, claim, legal action, or demand
            for damages arising from the use of this software.
          </p>

          <p>
            <strong>Indemnification:</strong> The user agrees to indemnify and
            hold harmless the author and provider of this software from any
            loss, damages, costs, or expenses, including legal fees, resulting
            from any violation by the user of these terms and conditions.
          </p>

          <p><strong>Assignment of Data Usage Rights</strong></p>

          <p>
            In addition to the above terms and conditions, the user acknowledges
            and agrees that any data entered or provided through the use of this
            software becomes the property of the software owner. The user
            irrevocably assigns and transfers to the software owner all rights,
            titles, and interests in such data, including, but not limited to,
            the rights of reproduction, distribution, processing, and any other
            usage rights, for commercial or non-commercial purposes, without the
            need for further authorization or consent.
          </p>

          <p>
            The user expressly waives any claims, rights, or interests in such
            data and agrees not to make any claims for compensation or any other
            form of compensation against the software owner for the use of the
            transferred data.
          </p>

          <p>
            The user understands and agrees that the software owner has the
            right to use the transferred data in accordance with applicable
            privacy and data protection laws, and that such data may be used to
            improve the software's functionality, for statistical purposes, for
            user experience customization, or for other legitimate purposes.
          </p>

          <p style="font-weight: bold; color: #28a745">
            <b-form-checkbox
              v-model="policy_accepted"
              name="policy"
              :value="1"
              :unchecked-value="0"
              >By accepting these terms and conditions, the user declares full
              understanding and acceptance of the provisions of both sections.
              The use of this software implies awareness and acceptance of the
              risks associated with data loss and the assignment of data usage
              rights.</b-form-checkbox
            >
          </p>

          <p><em>Last Updated: May 10, 2024</em></p>

          <br />

          <p>
            <strong>Descargo de Responsabilidad por Pérdida de Datos</strong>
          </p>

          <p>
            Al acceder y utilizar este software, el usuario acepta los
            siguientes términos y condiciones. Por favor, lea cuidadosamente
            antes de proceder.
          </p>

          <p>
            <strong>Aceptación de Riesgos:</strong> El usuario reconoce y acepta
            que el uso de este software conlleva riesgos de pérdida de datos.
            Tales riesgos pueden derivarse de fallas técnicas, errores humanos,
            ciberataques u otras causas imprevistas. El usuario comprende que la
            seguridad de los datos no puede garantizarse al cien por ciento, y
            no hay garantías explícitas o implícitas con respecto a la
            integridad de los datos.
          </p>

          <p>
            <strong>Descargo de Responsabilidad por Responsabilidad:</strong> En
            ningún caso, el autor o proveedor de este software será responsable
            por la pérdida de datos, daños directos o indirectos, daños
            consecuenciales, pérdidas financieras o cualquier otro daño o
            perjuicio derivado del uso o la imposibilidad de utilizar este
            software, incluso si el autor o proveedor ha sido informado de la
            posibilidad de tales daños.
          </p>

          <p>
            <strong>Limitación de Responsabilidad:</strong> Si la ley aplicable
            no permite la exclusión o limitación de responsabilidad por daños
            directos o indirectos, la responsabilidad total del autor o
            proveedor de este software se limitará al máximo permitido por la
            ley.
          </p>

          <p>
            <strong>Libertad de Exención:</strong> El usuario libera al autor y
            proveedor de este software de cualquier responsabilidad, reclamo,
            acción legal o demanda de daños derivados del uso de este software.
          </p>

          <p>
            <strong>Indemnización:</strong> El usuario acepta indemnizar y
            eximir de responsabilidad al autor y proveedor de este software por
            cualquier pérdida, daño, costo o gasto, incluidos los honorarios
            legales, derivados de cualquier violación por parte del usuario de
            estos términos y condiciones.
          </p>

          <p><strong>Asignación de Derechos de Uso de Datos</strong></p>

          <p>
            Además de los términos y condiciones anteriores, el usuario reconoce
            y acepta que cualquier dato ingresado o proporcionado mediante el
            uso de este software se convierte en propiedad del propietario del
            software. El usuario asigna irrevocablemente y transfiere al
            propietario del software todos los derechos, títulos e intereses
            sobre dichos datos, incluidos, entre otros, los derechos de
            reproducción, distribución, procesamiento y cualquier otro derecho
            de uso, con fines comerciales o no comerciales, sin necesidad de
            autorización o consentimiento adicional.
          </p>

          <p>
            El usuario renuncia expresamente a cualquier reclamación, derecho o
            interés sobre dichos datos y acepta no presentar reclamaciones de
            compensación o cualquier otra forma de compensación contra el
            propietario del software por el uso de los datos transferidos.
          </p>

          <p>
            El usuario comprende y acepta que el propietario del software tiene
            derecho a utilizar los datos transferidos de acuerdo con las leyes
            aplicables de privacidad y protección de datos, y que dichos datos
            pueden utilizarse para mejorar la funcionalidad del software, para
            fines estadísticos, para la personalización de la experiencia del
            usuario u otros fines legítimos.
          </p>

          <p style="font-weight: bold; color: #28a745">
            <b-form-checkbox
              v-model="policy_accepted"
              name="policy"
              :value="1"
              :unchecked-value="0"
              >Al aceptar estos términos y condiciones, el usuario declara
              comprender y aceptar plenamente las disposiciones de ambas
              secciones. El uso de este software implica el conocimiento y la
              aceptación de los riesgos asociados con la pérdida de datos y la
              asignación de derechos de uso de datos.</b-form-checkbox
            >
          </p>

          <p><em>Última Actualización: 10 de mayo de 2024</em></p>

          <br />

          <p>
            <strong
              >Clause de non-responsabilité pour la perte de données</strong
            >
          </p>

          <p>
            En accédant et en utilisant ce logiciel, l'utilisateur accepte les
            termes et conditions suivants. Veuillez lire attentivement avant de
            procéder.
          </p>

          <p>
            <strong>Acceptation des Risques:</strong> L'utilisateur reconnaît et
            accepte que l'utilisation de ce logiciel comporte des risques de
            perte de données. Ces risques peuvent découler de dysfonctionnements
            techniques, d'erreurs humaines, d'attaques informatiques ou d'autres
            causes imprévues. L'utilisateur comprend que la sécurité des données
            ne peut être garantie à cent pour cent, et qu'il n'y a aucune
            garantie explicite ou implicite concernant l'intégrité des données.
          </p>

          <p>
            <strong>Décharge de Responsabilité:</strong> En aucun cas, l'auteur
            ou le fournisseur de ce logiciel ne sera tenu responsable de la
            perte de données, des dommages directs ou indirects, des dommages
            consécutifs, des pertes financières ou de tout autre dommage
            résultant de l'utilisation ou de l'incapacité à utiliser ce
            logiciel, même si l'auteur ou le fournisseur a été informé de la
            possibilité de tels dommages.
          </p>

          <p>
            <strong>Limitation de Responsabilité:</strong> Si la loi applicable
            ne permet pas l'exclusion ou la limitation de responsabilité pour
            les dommages directs ou indirects, la responsabilité totale de
            l'auteur ou du fournisseur de ce logiciel sera limitée dans toute la
            mesure permise par la loi.
          </p>

          <p>
            <strong>Décharge:</strong> L'utilisateur dégage l'auteur et le
            fournisseur de ce logiciel de toute responsabilité, réclamation,
            action en justice ou demande de dommages et intérêts résultant de
            l'utilisation de ce logiciel.
          </p>

          <p>
            <strong>Indemnisation:</strong> L'utilisateur accepte d'indemniser
            et de dégager de toute responsabilité l'auteur et le fournisseur de
            ce logiciel pour toute perte, dommage, coût ou dépense, y compris
            les frais juridiques, résultant de toute violation par l'utilisateur
            de ces termes et conditions.
          </p>

          <p><strong>Cession des Droits d'Utilisation des Données</strong></p>

          <p>
            En plus des termes et conditions susmentionnés, l'utilisateur
            reconnaît et accepte que toute donnée saisie ou fournie par
            l'utilisation de ce logiciel devienne la propriété du propriétaire
            du logiciel. L'utilisateur cède de manière irrévocable et transfère
            au propriétaire du logiciel tous les droits, titres et intérêts sur
            ces données, y compris, mais sans s'y limiter, les droits de
            reproduction, de distribution, de traitement et tout autre droit
            d'utilisation, à des fins commerciales ou non commerciales, sans
            nécessité d'autorisation ou de consentement supplémentaire.
          </p>

          <p>
            L'utilisateur renonce expressément à toute réclamation, droit ou
            intérêt sur ces données et accepte de ne pas faire de demande de
            compensation ou de toute autre forme de rémunération contre le
            propriétaire du logiciel pour l'utilisation des données transférées.
          </p>

          <p>
            L'utilisateur comprend et accepte que le propriétaire du logiciel a
            le droit d'utiliser les données transférées conformément aux lois
            applicables sur la confidentialité et la protection des données, et
            que ces données peuvent être utilisées pour améliorer la
            fonctionnalité du logiciel, à des fins statistiques, pour la
            personnalisation de l'expérience utilisateur ou d'autres fins
            légitimes.
          </p>

          <p style="font-weight: bold; color: #28a745">
            <b-form-checkbox
              v-model="policy_accepted"
              name="policy"
              :value="1"
              :unchecked-value="0"
              >En acceptant ces termes et conditions, l'utilisateur déclare
              comprendre et accepter pleinement les dispositions des deux
              sections. L'utilisation de ce logiciel implique la connaissance et
              l'acceptation des risques associés à la perte de données et à la
              cession des droits d'utilisation des données.</b-form-checkbox
            >
          </p>

          <p><em>Dernière mise à jour : 10 mai 2024</em></p>

          <br />

          <p><strong>Haftungsausschluss für Datenverlust</strong></p>

          <p>
            Durch den Zugriff und die Nutzung dieser Software erklärt sich der
            Benutzer mit den folgenden Bedingungen einverstanden. Bitte lesen
            Sie sorgfältig bevor Sie fortfahren.
          </p>

          <p>
            <strong>Risikoakzeptanz:</strong> Der Benutzer erkennt an und
            akzeptiert, dass die Nutzung dieser Software Risiken des
            Datenverlusts mit sich bringt. Diese Risiken können durch technische
            Störungen, menschliche Fehler, Cyberangriffe oder andere
            unvorhergesehene Ursachen entstehen. Der Benutzer versteht, dass die
            Sicherheit der Daten nicht zu hundert Prozent gewährleistet werden
            kann und dass es keine ausdrücklichen oder stillschweigenden
            Garantien für die Datenintegrität gibt.
          </p>

          <p>
            <strong>Haftungsausschluss:</strong> Unter keinen Umständen haften
            der Autor oder Anbieter dieser Software für Datenverlust, direkte
            oder indirekte Schäden, Folgeschäden, finanzielle Verluste oder
            sonstige Schäden, die sich aus der Nutzung oder Unfähigkeit zur
            Nutzung dieser Software ergeben, auch wenn der Autor oder Anbieter
            über die Möglichkeit solcher Schäden informiert wurde.
          </p>

          <p>
            <strong>Haftungsbeschränkung:</strong> Sofern das anwendbare Recht
            den Ausschluss oder die Beschränkung der Haftung für direkte oder
            indirekte Schäden nicht zulässt, wird die Gesamthaftung des Autors
            oder Anbieters dieser Software auf das maximal zulässige Maß
            beschränkt.
          </p>

          <p>
            <strong>Freistellung:</strong> Der Benutzer entbindet den Autor und
            Anbieter dieser Software von jeglicher Haftung, Ansprüchen,
            rechtlichen Schritten oder Schadensersatzforderungen, die sich aus
            der Nutzung dieser Software ergeben.
          </p>

          <p>
            <strong>Freistellung:</strong> Der Benutzer verpflichtet sich, den
            Autor und Anbieter dieser Software von jeglichem Verlust, Schäden,
            Kosten oder Ausgaben, einschließlich Anwaltsgebühren, freizustellen
            und schadlos zu halten, die sich aus einem Verstoß des Benutzers
            gegen diese Allgemeinen Geschäftsbedingungen ergeben.
          </p>

          <p><strong>Übertragung der Nutzungsrechte an Daten</strong></p>

          <p>
            Zusätzlich zu den oben genannten Bedingungen erkennt der Benutzer an
            und stimmt zu, dass alle über die Nutzung dieser Software
            eingegebenen oder bereitgestellten Daten Eigentum des
            Softwarebesitzers werden. Der Benutzer überträgt unwiderruflich alle
            Rechte, Titel und Interessen an diesen Daten auf den
            Softwarebesitzer, einschließlich, aber nicht beschränkt auf die
            Rechte zur Vervielfältigung, Verbreitung, Verarbeitung und jegliche
            anderen Nutzungsrechte, für kommerzielle oder nichtkommerzielle
            Zwecke, ohne weitere Genehmigung oder Zustimmung.
          </p>

          <p>
            Der Benutzer verzichtet ausdrücklich auf alle Ansprüche, Rechte oder
            Interessen an diesen Daten und erklärt sich damit einverstanden,
            keine Ansprüche auf Entschädigung oder sonstige Formen der Vergütung
            gegenüber dem Softwarebesitzer für die Nutzung der übertragenen
            Daten geltend zu machen.
          </p>

          <p>
            Der Benutzer versteht und akzeptiert, dass der Softwarebesitzer das
            Recht hat, die übertragenen Daten gemäß den geltenden Datenschutz-
            und Datenschutzgesetzen zu verwenden, und dass diese Daten zur
            Verbesserung der Softwarefunktionalität, für statistische Zwecke,
            zur Anpassung der Benutzererfahrung oder für andere legitime Zwecke
            verwendet werden können.
          </p>

          <p style="font-weight: bold; color: #28a745">
            <b-form-checkbox
              v-model="policy_accepted"
              name="policy"
              :value="1"
              :unchecked-value="0"
              >Mit der Annahme dieser Allgemeinen Geschäftsbedingungen erklärt
              der Benutzer, die Bestimmungen beider Abschnitte vollständig zu
              verstehen und zu akzeptieren. Die Nutzung dieser Software
              impliziert das Bewusstsein und die Akzeptanz der mit dem
              Datenverlust und der Übertragung der Nutzungsrechte an Daten
              verbundenen Risiken.</b-form-checkbox
            >
          </p>

          <p><em>Letztes Update: 10. Mai 2024</em></p>
        </div>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
export default {
  data() {
    return {
      last_login: null,
      policy_accepted: null,
    };
  },

  components: {},

  methods: {},
  mounted() {},
  computed: {
    user: function () {
      return Auth.user;
    },
    gruppi: function () {
      var gruppi_lbl = "";
      for (var i in Auth.user.gruppi) {
        if (gruppi_lbl != "") {
          gruppi_lbl += ", ";
        }
        gruppi_lbl += Auth.user.gruppi[i].nome;
      }
      return gruppi_lbl;
    },
  },
  watch: {
    policy_accepted: function (new_value, old_value) {
      if (old_value == null) {
        return;
      }
      this.$http
        .post("/update/policy/user/" + this.user.id, {
          policy_accepted: this.policy_accepted,
        })
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.commit("msgPush", {
            msg: "Policy updated succesfully!",
            status: 1,
          });
        });
    },
  },
  created: function () {
    this.$http.get("/last/login").then((response) => {
      this.last_login = response.data;
    });
    this.policy_accepted = this.user.policy_accepted;
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
